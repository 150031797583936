import React from "react";
import { Button, Container, Divider, Header, Icon } from "semantic-ui-react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import ReactPlayer from "react-player";
import ScareBgm from "./ScareBgm";
import LovelyBgm from "./LovelyBgm";
import EnjoyBgm from "./EnjoyBgm";
import MemoryBgm from "./MemoryBgm";
import DreamBgm from "./DreamBgm";

export default class Talk extends React.Component {
  constructor() {
    super();
    this.userJsonStringUsers = localStorage.getItem("talk_user");
    this.talk_user = JSON.parse(this.userJsonStringUsers);
    this.usersJsonStringTimer = localStorage.getItem("timer");
    this.timer = JSON.parse(this.usersJsonStringTimer) * 60;
    this.usersJsonStringTurn = localStorage.getItem("turn");
    this.turn = JSON.parse(this.usersJsonStringTurn);
    this.talkThemeJsonString = localStorage.getItem("talk_theme");
    this.talk_theme = JSON.parse(this.talkThemeJsonString);
    this.talkGenreJsonString = localStorage.getItem("genre");
    this.genre = JSON.parse(this.talkGenreJsonString);
    console.log(this.talk_user);
    console.log(this.timer);
    console.log(this.turn);
    console.log(this.talk_theme);
    console.log(this.genre);
    this.state = { time: {}, seconds: this.timer };
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
    this.startTimer();
  }

  secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  }

  componentDidMount() {
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar });
  }

  startTimer() {
    if (this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });

    // Check if we're at zero.
    if (seconds == 0) {
      clearInterval(this.timer);
    }
  }

  decrementTurn(turn) {
    turn--;
    const usersJsonString = JSON.stringify(turn);
    console.log(turn);
    localStorage.setItem("turn", usersJsonString);
  }

  render() {
    const min =
      this.state.time.m >= 10 ? this.state.time.m : "0" + this.state.time.m;
    const sec =
      this.state.time.s >= 10 ? this.state.time.s : "0" + this.state.time.s;
    const turn = this.turn;
    if (min == 0 && sec == 0) {
      if (turn <= 1) {
        window.location.href = "/finish_page";
        return;
      }
      this.decrementTurn(this.turn);
      window.location.href = "/decide_user_page";
      return;
    }

    switch (this.genre) {
      case 0:
        this.talkBgm = () => <ScareBgm />;
        break;
      case 1:
        this.talkBgm = () => <LovelyBgm />;
        break;
      case 2:
        this.talkBgm = () => <EnjoyBgm />;
        break;
      case 3:
        this.talkBgm = () => <MemoryBgm />;
        break;
      case 4:
        this.talkBgm = () => <DreamBgm />;
        break;
    }
    return (
      <Container className="talk_page">
        <Container textAlign="center">
          <Header as="h2">トークタイム！</Header>
        </Container>
        <Divider hidden></Divider>
        <Container textAlign="center">
          <Header as="h1">話し手：{this.talk_user}</Header>
        </Container>
        <Divider hidden></Divider>
        <Container textAlign="center">
          <Header as="h1">{this.talk_theme}</Header>
        </Container>
        <Divider hidden></Divider>
        <Container textAlign="center">
          <Header as="h1" id="timerPage">
            {min} : {sec}
          </Header>
        </Container>
        <Divider hidden></Divider>
        <Container textAlign="center">
          <Header as="h1">残りターン数：{this.turn}回</Header>
        </Container>
        {this.talkBgm()}
      </Container>
    );
  }
}
