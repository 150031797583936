import React, { Component } from "react";
import {
  Button,
  Container,
  Header,
  Icon,
  Form,
  Divider,
} from "semantic-ui-react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

export default function settingTurn() {
  function setTurn(turn) {
    const usersJsonString = JSON.stringify(turn);
    console.log(turn);
    localStorage.setItem("turn", usersJsonString);
  }
  return (
    <div className="settingTurn">
      <Container textAlign="center">
        <Header as="h2">ターン数の設定</Header>
      </Container>
      <Container textAlign="center">
        <Divider hidden></Divider>
        ターン数（回）を入力してください。
        <Divider hidden></Divider>
      </Container>
      <Container>
        <Divider hidden></Divider>
        <Form>
          <select id="turn" name="number">
            <option value="1">1回</option>
            <option value="2">2回</option>
            <option value="3">3回</option>
            <option value="4">4回</option>
            <option value="5" selected>
              5回
            </option>
            <option value="6">6回</option>
            <option value="7">7回</option>
            <option value="8">8回</option>
            <option value="9">9回</option>
            <option value="10">10回</option>
            <option value="11">11回</option>
            <option value="12">12回</option>
            <option value="13">13回</option>
            <option value="14">14回</option>
            <option value="15">15回</option>
          </select>
        </Form>
        <Divider hidden></Divider>
        <Divider hidden></Divider>
        <Divider hidden></Divider>
        <Link
          to="/decide_user_page"
          onClick={() => {
            const turn = document.getElementById("turn").value;
            setTurn(turn);
          }}
        >
          <Button size="massive" inverted color="blue" fluid>
            次へ
          </Button>
        </Link>
      </Container>
    </div>
  );
}
