import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Top from "./top";
import AddUser from "./addUser";
import SettingTimer from "./settingTimer";
import SettingTurn from "./settingTurn";
import DecideUser from "./decideUser";
import DecideTheme from "./decideTheme";
import Talk from "./talk";
import Finish from "./finish";
import MainBgm from "./mainBgm";

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
    };
    this.prevName = "";
  }

  updateUserState(index, newusername) {
    if (newusername === this.prevName) {
      return;
    }
    this.prevName = newusername;
    this.setState((prevState) => ({
      users: [
        ...prevState.users.slice(0, index),
        { id: index, name: newusername },
        ...prevState.users.slice(index + 1),
      ],
    }));
  }

  saveUsers() {
    const usersJsonString = JSON.stringify(this.state.users);
    localStorage.setItem("users", usersJsonString);
  }

  render() {
    const mainBgm = () => <MainBgm />;
    return (
      <>
        <Router>
          <Switch>
            <Route path="/add_user">
              {mainBgm()}
              <AddUser
                updateUserState={this.updateUserState.bind(this)}
                users={this.state.users}
                saveUsers={this.saveUsers.bind(this)}
              />
            </Route>
            <Route path="/setting_timer_page">
              {mainBgm()}
              <SettingTimer />
            </Route>
            <Route path="/setting_turn_page">
              {mainBgm()}
              <SettingTurn />
            </Route>
            <Route path="/decide_user_page">
              {mainBgm()}
              <DecideUser />
            </Route>
            <Route path="/decide_theme_page">
              {mainBgm()}
              <DecideTheme />
            </Route>
            <Route path="/talk_page">
              <Talk />
            </Route>
            <Route path="/finish_page">
              {mainBgm()}
              <Finish />
            </Route>
            <Route path="/">
              {mainBgm()}
              <Top />
            </Route>
          </Switch>
        </Router>
      </>
    );
  }
}
