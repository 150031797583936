import React from "react";
import { Button, Container, Divider, Header, Icon } from "semantic-ui-react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

export default class Finish extends React.Component {
  constructor() {
    super();
    this.usersJsonStringTurn = localStorage.getItem("turn");
    this.turn = JSON.parse(this.usersJsonStringTurn);
  }

  render() {
    return (
      <div className="finish">
        <Container textAlign="center">
          <Header as="h2">トークが終了しました。</Header>
        </Container>

        <Container id="finishButton">
          <Link to="/top_page">
            <Button content="Finish" size="massive" inverted color="blue" fluid>
              Topへ
            </Button>
          </Link>
        </Container>
      </div>
    );
  }
}
