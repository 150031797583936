import React from "react";
import { Button, Container, Divider, Header, Icon } from "semantic-ui-react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

export default function DecideUser() {
  const usersJsonStringUsers = localStorage.getItem("users");
  const users = JSON.parse(usersJsonStringUsers);

  function setTalkUser(talk_user) {
    const usersJsonString = JSON.stringify(talk_user);
    console.log(talk_user);
    localStorage.setItem("talk_user", usersJsonString);
  }

  function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  const number = getRandomInt(users.length);
  console.log(number);
  const talk_user = users[number].name;
  return (
    <Container textAlign="center" className="decideUser">
      <Header as="h2">トークする人</Header>
      <Divider hidden></Divider>
      <Divider hidden></Divider>
      <Header as="h1">{talk_user}</Header>
      <Link
        to="/decide_theme_page"
        onClick={() => {
          setTalkUser(talk_user);
        }}
      >
        <Divider hidden></Divider>
        <Divider hidden></Divider>
        <Divider hidden></Divider>
        <Button size="massive" inverted color="blue" fluid>
          次へ
        </Button>
      </Link>
    </Container>
  );
}
