import React from "react";
import { Button, Container, Header, Icon } from "semantic-ui-react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom"; // BrowserRouter, Switch, Route, Linkはセットでインポート

export default function Top() {
  return (
    <div className="top">
      <Container textAlign="center">
        <div className="appName">Wa : talk</div>
      </Container>
      <Container textAlign="center">
        <Icon name="talk" size="massive" />
      </Container>
      <Container textAlign="center">
        <Header as="h2" icon>
          みんなで"わ"になって話そう！
          <Header.Subheader>1つの端末で。複数人と。</Header.Subheader>
        </Header>
      </Container>
      <Container>
        <Link to="/add_user">
          <Button content="talk!" size="massive" inverted color="blue" fluid>
            talk!
          </Button>
        </Link>
      </Container>
    </div>
  );
}
