import React from "react";
import { Button, Container, Divider, Header, Icon } from "semantic-ui-react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import TalkTheme from "./talk_theme";

export default function DecideTalkTheme() {
  const theme = TalkTheme();
  console.log(theme);

  function setTalkTheme(theme) {
    const themeJsonString = JSON.stringify(theme);
    console.log(theme);
    localStorage.setItem("talk_theme", themeJsonString);
  }
  function setTalkGenre(genre) {
    const genreJsonString = JSON.stringify(genre);
    console.log(genre);
    localStorage.setItem("genre", genreJsonString);
  }

  function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  const genre_number = getRandomInt(5);
  const genre = theme.themes[genre_number];
  const talk_theme = genre[getRandomInt(genre.length)];
  console.log(genre_number);
  console.log(talk_theme);
  return (
    <Container textAlign="center" className="decideTheme">
      <Header as="h2">トークテーマ</Header>
      <Divider hidden></Divider>
      <Divider hidden></Divider>
      <Header as="h1">{talk_theme}</Header>
      <Link
        onClick={() => {
          setTalkTheme(talk_theme);
          setTalkGenre(genre_number);
          window.location.href = "/talk_page";
        }}
      >
        <Divider hidden></Divider>
        <Divider hidden></Divider>
        <Divider hidden></Divider>
        <Button size="massive" inverted color="blue" fluid>
          次へ
        </Button>
      </Link>
    </Container>
  );
}
