import React, { Component } from "react";
import {
  Button,
  Container,
  Header,
  Icon,
  Form,
  Divider,
} from "semantic-ui-react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

export default function settingTimer() {
  function setTimer(timer) {
    const usersJsonString = JSON.stringify(timer);
    console.log(timer);
    localStorage.setItem("timer", usersJsonString);
  }
  return (
    <div className="settingTimer">
      <Container textAlign="center">
        <Header as="h2">トークタイムの設定</Header>
      </Container>
      <Divider hidden></Divider>
      <Container textAlign="center">
        一人当たりのトーク時間（分）を入力してください。
      </Container>
      <Divider hidden></Divider>
      <Container>
        <Form id="add-user-form">
          <Divider hidden></Divider>
          <select id="timer" name="number">
            <option value="1">1分</option>
            <option value="2">2分</option>
            <option value="3" selected>
              3分
            </option>
            <option value="4">4分</option>
            <option value="5">5分</option>
            <option value="6">6分</option>
            <option value="7">7分</option>
            <option value="8">8分</option>
            <option value="9">9分</option>
            <option value="10">10分</option>
            <option value="11">11分</option>
            <option value="12">12分</option>
            <option value="13">13分</option>
            <option value="14">14分</option>
            <option value="15">15分</option>
          </select>
        </Form>
        <Divider hidden></Divider>
        <Divider hidden></Divider>
        <Divider hidden></Divider>
        <Link
          to="/setting_turn_page"
          onClick={() => {
            const timer = document.getElementById("timer").value;
            setTimer(timer);
          }}
        >
          <Button size="massive" inverted color="blue" fluid>
            次へ
          </Button>
        </Link>
      </Container>
    </div>
  );
}
