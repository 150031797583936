import React from "react";
import ReactPlayer from "react-player";

export default function ScareBgm() {
  return (
    <ReactPlayer
      url="https://watalk-bgm.s3-ap-northeast-1.amazonaws.com/scare(demo).m4a"
      playing={true}
      loop={true}
      width={0}
      height={0}
    ></ReactPlayer>
  );
}
