export default function talkTheme() {
  return {
    themes: {
      0: [
        "怖かった映画",
        "意味がわかると怖い話",
        "恐かった異性",
        "一人で経験した怖い話",
        "不思議な体験の話",
      ],
      1: [
        "初恋の話",
        "好きな異性の話",
        "ドキッとする一言",
        "好きなのは肉食系、草食系",
        "理想のプロポーズ",
        "恋人に求めるもの",
        "結婚対象となる恋人",
        "衝撃的だった異性の言動",
        "浮気についてどう思う？",
        "追いかけたい？追いかけられたい？",
        "好きな異性の話",
      ],
      2: [
        "最高のご飯のお供",
        "地元の話",
        "休日の過ごし方",
        "趣味の話",
        "今まで付けられたあだ名",
        "実家の独自文化",
        "特技について",
        "苦手なものについて",
        "生活の中のこだわり",
        "自分を動物に例えると",
        "好きな芸能人",
        "好きな食べ物",
        "好きな音楽",
        "好きな季節",
        "好きなスポーツ",
        "こんな癖があります。",
        "ファッションのこだわり",
        "自分の長所、短所",
        "自分なりのリフレッシュ方法",
        "尊敬する人",
        "好きな名言",
        "昔からの宝物",
        "友達に鼻毛出てるって言える？",
        "友達に何円までなら貸せる？",
        "友達に借りた最高額",
        "たけのこ派？きのこ派？。",
        "好きな映画",
        "朝方？夜型？",
        "健康のために心がけていること",
        "得意料理",
        "好きなものは先に食べる派？",
        "最近見た夢について",
        "犬派？猫派？",
        "好きなブランド",
        "家で一人の時何する？",
        "カラオケの十八番",
        "好きな芸能人",
        "好きな季節",
        "起きて最初にすること",
        "実家の独自文化",
        "好きな匂い",
        "駄菓子といえばこれ",
        "私がどうしても克服できないこと。",
        "最近感動したこと",
        "好きな果物",
        "好きなYouTuber",
      ],
      3: [
        "学生時代面白かった友達の話",
        "学生時代面白かった先生の話",
        "部活動であった面白かった話",
        "小さい頃の夢",
        "小さい頃熱中していたこと",
        "こんな習い事してました。",
        "今だから言えるぶっちゃけ話",
        "人生最大の失敗",
        "人生で一番辛かったこと",
        "過去最大のサプライズ",
        "昔学校で流行った遊び",
        "昔学校で流行った言葉",
        "喧嘩した思い出",
        "学生時代の休み時間の過ごし方",
        "学校のお気に入りの場所",
        "好きだった授業",
        "得意科目",
        "学生時代最大の怪我",
        "青春を感じた瞬間",
        "授業をさぼってたことは？",
        "入っていた部活",
        "昔の恥ずかしい話",
        "昔ハマっていたゲーム",
      ],
      4: [
        "何か一つだけ無人島に持っていくなら",
        "一億円あったらどう使う",
        "１ヶ月の休暇があったらどう使う。",
        "明日死ぬなら何する。",
        "動物に生まれ変わったら何になりたい？",
        "理想の旅行プラン。",
        "無人島に行ったら",
        "100年後の未来に行ったら",
        "100年前の過去に行ったら",
        "将来の夢",
        "生まれ変わるなら男性？女性？",
        "人間は死後どうなると思う？",
        "子供につけたい名前",
        "一日だけ異性になったら何をする",
        "もし一つだけ魔法が使えるなら",
        "落ちているお金いくらまで拾う？",
        "何にでもなれるなら何になる？",
        "飼ってみたい生き物",
        "お金が無限にあったら",
        "生まれ変わりたい人",
      ],
    },
  };
}
