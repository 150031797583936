import React, { Component } from "react";
import {
  Button,
  Container,
  Header,
  Icon,
  Form,
  Divider,
} from "semantic-ui-react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

export default class AddUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
    };
    this.userIndex = 1;
  }

  handleOnChange = (e) => {
    this.value = e.target.value;
  };

  clearForm = () => {
    document.getElementById("add-user-form").reset();
  };

  render() {
    const users = this.props.users.map((users, index) => (
      <User key={index} {...users} />
    ));
    return (
      <div className="addUser">
        <Container textAlign="center">
          <Header as="h2">ユーザーの追加</Header>
        </Container>
        <Divider hidden></Divider>
        <Container textAlign="center">
          参加するユーザーを追加してください。
        </Container>
        <Divider hidden></Divider>
        <Container>
          <Form id="add-user-form">
            <Form.Group widths="equal">
              <Form.Input
                fluid
                label="user name"
                placeholder="ユーザー名"
                onChange={(e) => this.handleOnChange(e)}
              />
            </Form.Group>
            <Container textAlign="center">{users}</Container>
            <Divider hidden></Divider>
            <Form.Button
              onClick={() => {
                this.props.updateUserState(this.userIndex++, this.value);
                this.clearForm();
              }}
              size="massive"
              color="grey"
              fluid
            >
              追加
            </Form.Button>
          </Form>
          <Divider hidden></Divider>
          <Divider hidden></Divider>
          <Link
            to="/setting_timer_page"
            onClick={() => {
              this.props.saveUsers();
            }}
          >
            <Button size="massive" inverted color="blue" fluid>
              次へ
            </Button>
          </Link>
          <Divider hidden></Divider>
          <Divider hidden></Divider>
        </Container>
      </div>
    );
  }
}

function User(props) {
  return (
    <Container>
      <Header>{props.name}</Header>
    </Container>
  );
}
